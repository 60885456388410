import { AppEnvStates } from '../../src/constants';

/**
 * Returns the absolute base URL based on the current environment.
 *
 * It depends on APP_ENV and baseUrl being set.
 *
 * e.g. in production, this will be https://www.secondnature.io
 * Locally, this will be http://localhost:3000
 *
 * @returns {string} The absolute base URL.
 */
export const getAbsoluteBaseURL = (): string => {
  if (!process.env.baseUrl) {
    throw new Error('baseUrl not set');
  }

  if (!process.env.APP_ENV) {
    throw new Error('APP_ENV not set');
  }

  const protocol = [
    AppEnvStates.LOCAL,
    AppEnvStates.CYPRESS,
    AppEnvStates.TEST,
  ].includes(process.env.APP_ENV as AppEnvStates)
    ? 'http'
    : 'https';

  return `${protocol}://${process.env.baseUrl}`;
};
