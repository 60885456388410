export default {
  lightGray: '#aaa',
  veryLightGray: '#edecf0',
  white: '#fff',

  // Second Nature original brand colours - now deprecated
  snBlack: '#00263E',
  greyCloud: '#7F929E', // (or SN black 50%)
  stone: '#CCD4D8', // (or SN black 20%)
  pebbleWhite: '#E5E9EB', // (or SN black 10%)
  marbleWhite: '#F1F2F3',
  darkGray: '#4D575F',

  snBlue: '#00B9CD',
  skyBlue: '#7FDCE6', // (or SN blue 50%)
  duckEgg: '#CCF1F5', // (or SN blue 20%)
  mintWhite: '#E5F8FA', // (or SN blue 10%)

  snOrange: '#FA4F26',
  sunset: '#FCA792', // (or SN orange 50%)
  peachWhite: '#FEEDE9', // (or SN orange 10%)

  lavender: '#E9DEFA', // (or SN purple 20%)
  aubergineWhite: '#F4EEFC', // (or SN purple 10%)
  darkPurple2: '#4A2E72',

  snGreen: '#00C38C',
  treeGreen: '#7FE1C5', // (or SN green 50%)
  grassGreen: '#CCF3E8', // (or SN green 20%)
  appleWhite: '#E5F9F3', // (or SN green 10%)
  darkGreen: '#009C70',

  // Social login colours
  facebookBlue: '#1977F3',
  appleBlack: '#000',

  // Second Nature 2022 rebrand colours
  // Avoid using - use Tailwind classes instead
  blue: '#042D49',
} as const;
