import { ParsedUrlQuery } from 'querystring';

import {
  GetStaticPaths,
  GetStaticProps,
  GetStaticPropsContext,
  GetStaticPropsResult,
} from 'next';

// NB need to use relative path here instead of aliases due to aliases being out of scope in
// guidesUtils.test.ts
import { countryCodePricingMap } from '../../src/constants';
import { getCountryCodeFromLocale } from '../../src/constants/internationalConstants';

import {
  ArticleCategoriesNodeType,
  CategoryType,
  FeaturedImageSizeType,
  RecentArticleEdgeType,
  RecentArticleType,
  CategoryFieldsFragment,
  CommentFieldsFragment,
  CategoryPageInfoType,
  ArticleProps,
  LocalPageProps,
  HomeFeaturedArticleType,
} from '@nextTypes/guides';

import { ErrorTracking } from '@common/errorTracking';
import { getAbsoluteBaseURL } from './getAbsoluteBaseURL';

/**
 * POST a GraphQL query to our guides API (https://guides.secondnature.io/graphql).
 *
 * @param {string} query - A GraphQL query, as a string.
 * @return {Promise<any>} JSON response from the Guides endpoint.
 */
/* eslint-disable @typescript-eslint/no-explicit-any */
export const postGuidesQuery = async (query: string): Promise<any> => {
  const res = await fetch('https://guides.secondnature.io/graphql', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ query }),
  });

  if (!res.ok) {
    /**
     * If we're seeing this error there may be an issue with the WordPress
     * server/s. Try redeploying the WordPress EC2 instances and see if that
     * fixes it.
     */
    throw new Error(`Failed to fetch guides data: ${res.statusText}`);
  }

  // Default typing of Response.json() is 'any'
  return await res.json();
};

export const calculateCategory = (
  categoryEdges: ArticleCategoriesNodeType[],
): CategoryType => {
  // Firstly, filter out the 'Featured' category as it's only used for marking articles as featured
  categoryEdges = categoryEdges.filter(edge => edge.node.name !== 'Featured');

  let category: CategoryType;
  let subcategory: CategoryType | null | undefined;

  if (categoryEdges.length === 1) {
    const node = categoryEdges[0].node;

    if (node.parent) {
      category = node.parent;
      subcategory = { name: node.name, slug: node.slug };
    } else {
      category = { name: node.name, slug: node.slug };
    }
  } else {
    // If multiple categories/subcategories, then find the (primary) parent category

    // The categories returned are typically in alphabetical order (i.e. parent category isn't guaranteed to
    // be first in the list), so we need to manually find it - the primary parent category should be the one
    // with its parent set to null
    const parentEdge = categoryEdges.find(edge => !edge.node.parent);

    if (parentEdge) {
      // Found the primary parent category
      category = { name: parentEdge.node.name, slug: parentEdge.node.slug };
    } else {
      // Otherwise if no primary parent category (shouldn't happen in practice if categories are allocated
      // correctly in WP admin) then use the parent of the first item in the list
      const node = categoryEdges[0].node;
      category = { name: node.name, slug: node.slug };
    }
  }

  if (subcategory) {
    return {
      name: subcategory.name,
      slug: `${category.slug}/${subcategory.slug}`,
    };
  }

  return {
    name: category.name,
    slug: category.slug,
  };
};

export const getMagazineFeaturedImageSize = (
  featuredImageSizes: FeaturedImageSizeType[],
): { sourceUrl: string } => // The full featured image may be 1-2MB or more, so use a smaller version if possible
  featuredImageSizes.find(
    // 'magazine' is a constant size of 710x375, whereas most other sizes differ in aspect ratio
    featuredImageSize => featuredImageSize.name === 'magazine',
  ) || featuredImageSizes[0];

export const getSquareImageSize = (
  featuredImageSizes: FeaturedImageSizeType[],
): { sourceUrl: string } =>
  featuredImageSizes.find(
    featuredImageSize => featuredImageSize.name === 'square',
  ) || featuredImageSizes[0];

export const getRecentArticles = (recentPosts: {
  edges: RecentArticleEdgeType[];
}): RecentArticleType[] => {
  const recentArticles: RecentArticleType[] = recentPosts.edges.map(
    postEdge => {
      const node = postEdge.node;
      const category = calculateCategory(node.categories.edges);

      const canonicalSlug =
        category.name === 'Local' // For Local use one forward slash in the slug link, e.g. bristol/weight-watchers
          ? node.slug.replace('-', '/')
          : node.slug;

      const prefix =
        category.name === 'Stories' || category.name === 'Blog'
          ? ''
          : '/guides';

      const href = `${prefix}/${category.slug}/${canonicalSlug}`;

      const featuredImageUrl = node.featuredImage
        ? getMagazineFeaturedImageSize(node.featuredImage.mediaDetails.sizes)
            .sourceUrl
        : '';

      return {
        featuredImageUrl,
        heroImageUrl: node.featuredImage?.sourceUrl || '',
        category: category.name,
        href,
        title: node.title,
        description: node.seo ? node.seo.metaDesc : '',
        authorName: node.author ? node.author.name : '',
        authorImageUrl: node.author ? node.author.avatar.url : '',
        date: node.date,
      };
    },
  );

  return recentArticles;
};

export const getGuidesArticles = (
  articles: RecentArticleEdgeType[],
): HomeFeaturedArticleType[] =>
  articles.map(article => {
    const node = article.node;

    const category = calculateCategory(node.categories.edges);
    const href = `/guides/${category.slug}/${node.slug}`;

    let backgroundImage;

    const featuredImageSizes = node.featuredImage?.mediaDetails.sizes;
    if (featuredImageSizes) {
      // The full featured image may be 1-2MB or more, so use a smaller version if possible
      const featuredImage =
        featuredImageSizes.find(
          featuredImageSize => featuredImageSize.name === 'medium_large',
        ) || featuredImageSizes[0];

      backgroundImage = featuredImage.sourceUrl;
    } else {
      backgroundImage = '';
    }

    return {
      title: node.title,
      category: category.name,
      href,
      backgroundImage,
      description: node.seo?.metaDesc,
      authorName: node.author?.name,
    };
  });

export const getTakeOurHealthQuizLink = (
  utmMedium?: string,
  utmCampaign?: string,
  utmSource?: string,
  utmContent?: string,
  isGlp1Rejoiner?: boolean,
): string => {
  const page = isGlp1Rejoiner ? 'restart/welcome-back' : 'get-plan';
  let url = `/${page}`;

  if (utmSource || utmMedium || utmCampaign || utmContent) {
    url += '?';
    if (utmSource) {
      url += `utm_source=${utmSource}&`;
    }

    if (utmMedium) {
      url += `utm_medium=${utmMedium}&`;
    }

    if (utmCampaign) {
      url += `utm_campaign=${utmCampaign}&`;
    }

    if (utmContent) {
      url += `utm_content=${utmContent}&`;
    }

    // Remove trailing '&'
    url = url.slice(0, -1);
  }

  return url;
};

// Removes the -country-xx 2 character locale from the end of the slug if present
const stripLocaleFromSlug = (
  postEdge: RecentArticleEdgeType,
): RecentArticleEdgeType => {
  if (/-country-[a-z]{2}$/.test(postEdge.node.slug)) {
    // Make a deep copy
    const postEdgeCopy = JSON.parse(JSON.stringify(postEdge));

    postEdgeCopy.node.slug = postEdgeCopy.node.slug.replace(
      /-country-[a-z]{2}$/,
      '',
    );

    return postEdgeCopy;
  }

  return postEdge;
};

// For guides posts in general there may be up to 2 underlying posts for a particular slug: a variant to
// display for the current locale and one to display for all countries in general, e.g.:
// - slug: weight-loss-plateaus-explained-country-uk / tag: UK
// - slug: weight-loss-plateaus-explained / tag: ALL_COUNTRIES
// On the website we only ever want to show this as 1 single post with the shorter version of the slug, so
// this function combines two consecutive posts into a single post where applicable
export const combinePostsForCountryVariants = (
  recentPosts: {
    edges: RecentArticleEdgeType[];
    pageInfo?: CategoryPageInfoType;
  },
  maxPostsToShowPerPage: number,
): {
  edges: RecentArticleEdgeType[];
  pageInfo: CategoryPageInfoType;
} => {
  const combinedRecentPostEdges: RecentArticleEdgeType[] = [];
  let hasNextPage = recentPosts.pageInfo?.hasNextPage || false;
  let endCursor = '';

  for (let i = 0; i < recentPosts.edges.length; i += 1) {
    if (i === recentPosts.edges.length - 1) {
      // If reached the end of the list then update the endCursor to this last item
      const postEdge = stripLocaleFromSlug(recentPosts.edges[i]);
      endCursor = postEdge.cursor;
      combinedRecentPostEdges.push(postEdge);
      break;
    }

    let didTwoPostsCombine = false;
    const currentPostSlug = recentPosts.edges[i].node.slug;
    const nextPostSlug = recentPosts.edges[i + 1].node.slug;

    if (
      nextPostSlug.startsWith(currentPostSlug) &&
      /-country-[a-z]{2}$/.test(nextPostSlug)
    ) {
      // If the next post is a country variant of the current post, then combine as the current post
      combinedRecentPostEdges.push(recentPosts.edges[i]);
      didTwoPostsCombine = true;
    } else if (
      currentPostSlug.startsWith(nextPostSlug) &&
      /-country-[a-z]{2}$/.test(currentPostSlug)
    ) {
      // If the current post is a country variant of the next post, then combine as the next post
      combinedRecentPostEdges.push(recentPosts.edges[i + 1]);
      didTwoPostsCombine = true;
    } else {
      combinedRecentPostEdges.push(stripLocaleFromSlug(recentPosts.edges[i]));
    }

    if (didTwoPostsCombine) {
      // Set the endCursor to the second of the two posts (the next post)
      endCursor = recentPosts.edges[i + 1].cursor;

      // Skip an extra 1 to skip over the next post
      i += 1;
    } else {
      endCursor = recentPosts.edges[i].cursor;
    }

    // Check to see if we've now reached our maximum allowed posts to show per page
    if (combinedRecentPostEdges.length >= maxPostsToShowPerPage) {
      if (!hasNextPage && i < recentPosts.edges.length - 1) {
        // If there were no more posts in the database but there are more posts in this batch that we didn't
        // iterate over, then indicate that there are more posts to be fetched
        hasNextPage = true;
      }

      break;
    }
  }

  return {
    edges: combinedRecentPostEdges,
    pageInfo: {
      hasNextPage,
      endCursor,
    },
  };
};

/**
 * This function is used for when we want all paths to be generated at run time. None will be generated during the build.
 * Each path will be generated on first use - and then stored locally on the web server that served the request for any future requests.
 * https://nextjs.org/docs/basic-features/data-fetching/get-static-paths#generating-paths-on-demand
 *
 * Secondly, fallback: 'blocking' will mean that if the page hasn't been cached, the page will wait until NextJS renders the page
 * while it fetches the data to populate it - before sending it to the client.
 * https://nextjs.org/docs/api-reference/data-fetching/get-static-paths#fallback-blocking
 */
export const getEmptyStaticPaths: GetStaticPaths = () => ({
  paths: [],
  fallback: 'blocking',
});

export interface ArticlePageParams extends ParsedUrlQuery {
  postSlug: string | string[];
}

export const handleArticleStaticProps = async (
  { params, locale }: GetStaticPropsContext<ArticlePageParams>,
  pageType: 'Guides' | 'Local' | 'Stories' | 'Blog',
): Promise<GetStaticPropsResult<ArticleProps>> => {
  const postSlug = params?.postSlug;

  try {
    if (!postSlug) {
      throw new Error('No postSlug in query');
    }

    let slug: string;

    if (Array.isArray(postSlug)) {
      if (pageType === 'Local') {
        // Used for /guides/local/article
        // postSlug should be a length of two, the last two elements after /guides/local
        // e.g. /guides/local/surrey/nutritionists
        // WP slugs need to be hyphenated, e.g. /guides/local/surrey/nutritionists -> surrey-nutritionists
        slug = postSlug.join('-');
      } else {
        // Used for normal guides pages
        slug = postSlug[postSlug.length - 1];
      }
    } else {
      slug = postSlug;
    }

    let whereArgs;
    if (locale) {
      // If there is a variant of a post with content localised for a particular country, e.g.:
      // - slug: weight-loss-plateaus-explained-country-uk / tag: UK / tagSlug: uk
      //
      // ...then there should also be a corresponding post to show to all other countries, e.g.:
      // - slug: weight-loss-plateaus-explained / tag: ALL_COUNTRIES / tagSlug: all_countries
      //
      // ...alternatively instead of an "all countries" post there might be a "catch all" post, e.g.:
      // - slug: weight-loss-plateaus-explained / tag: CATCH_ALL / tagSlug: catch_all
      //
      // Here "catch all" refers to a post for all countries that should be excluded from the guides category
      // pages, as it only contains fallback content to show for visitors that have stumbled on a link to a
      // country-specific guide but are from a different country (e.g. a US visitor finds a link on the web
      // to a UK-only guide such as https://www.secondnature.io/uk/guides/weight-watchers-vs-slimming-world -
      // in this case we want to show them some form of content instead of a 404 error or a /guides redirect)
      //
      // So, in GraphQL we want to look up the country-localised variant and also the ALl_COUNTRIES /
      // CATCH_ALL post if the country variant doesn't exist
      whereArgs = `{
        nameIn: [ "${slug}", "${slug}-country-${locale}" ],
        tagSlugIn: [ "all_countries", "catch_all", "${locale}" ],
      }`;
    } else {
      whereArgs = `{ name: "${slug}", tagSlugIn: [ "all_countries", "catch_all" ] }`;
    }

    let categoryName;

    // Blog and Story pages are special cases where categories are not used in the post slug
    if (pageType === 'Blog' || pageType === 'Stories') {
      categoryName = pageType;
    } else if (Array.isArray(postSlug)) {
      // Find the category (or subcategory if available) from the second last element in the parsed URL
      // (the last element in the array is the actual post slug itself)
      categoryName = postSlug.slice(-2, -1)[0];
    }

    const sameCategoryPostsWhereArgs = categoryName
      ? `{
           categoryName: "${categoryName}",
           tagSlugIn: ["all_countries", "${locale}" ],
           orderby: {
             field: DATE,
             order: DESC
           }
         }`
      : '{ }';

    const graphQLResponse = await fetch(
      'https://guides.secondnature.io/graphql',
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          query: `
          ${CategoryFieldsFragment}
          ${CommentFieldsFragment}
          query {
            allCategories: categories(first: 100, where: {shouldOutputInFlatList: true}) {
              edges {
                node {
                  ...CategoryFields
                }
              }
            }
            posts(where: ${whereArgs}) {
              edges {
                node {
                  slug
                  postId
                  title
                  date
                  seo {
                    title
                    metaDesc
                  }
                  content
                  author {
                    name
                    avatar {
                      url
                    }
                  }
                  categories(where: {shouldOutputInFlatList: true}) {
                    edges {
                      node {
                        ...CategoryFields
                      }
                    }
                  }
                  featuredImage {
                    sourceUrl
                    mediaDetails {
                      width
                      height
                      sizes {
                        name
                        sourceUrl
                      }
                    }
                  }
                  comments (first: 1000) { # default is 15, so raise this to a reasonable limit
                    nodes {
                      ...CommentFields
                      replies: children {
                        nodes {
                          ...CommentFields
                          replies: children {
                            nodes {
                              ...CommentFields
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
            sameCategoryPosts: posts(first: 1000,
              where: ${sameCategoryPostsWhereArgs},
            ) {
              pageInfo {
                endCursor
                hasNextPage
              }
              edges {
                cursor
                node {
                  slug
                  title
                  seo {
                    metaDesc
                  }
                  categories(where: {shouldOutputInFlatList: true}) {
                    edges {
                      node {
                        ...CategoryFields
                      }
                    }
                  }
                  featuredImage {
                    mediaDetails {
                      sizes {
                        name
                        sourceUrl
                      }
                    }
                  }
                }
              }
            }
          }
        `,
        }),
      },
    );

    if (!graphQLResponse.ok) {
      throw new Error(
        `Failed to fetch guides data: ${graphQLResponse.statusText}`,
      );
    }

    const json = await graphQLResponse.json();

    // We have an array of posts that should contain the slug requested (the post for all countries), and
    // possibly also an entry that contains the country-localised variant, e.g.:
    // - weight-loss-plateaus-explained
    // - weight-loss-plateaus-explained-country-uk
    // (there should never be more than 2 entries if the posts have been tagged correctly)

    if (!json.data?.posts?.edges?.length) {
      return {
        notFound: true,
      };
    }

    const postEdges = json.data.posts.edges;
    let postEdge;
    if (postEdges.length === 1) {
      postEdge = postEdges[0];
    } else {
      // If there is more than one entry, then the country-localised variant for the current country should
      // be somewhere in the list (the list can be in any order)
      postEdge = postEdges.find((edge: { node: { slug: string } }) =>
        edge.node.slug.endsWith(`-country-${locale}`),
      );
    }

    if (!postEdge) {
      return {
        notFound: true,
      };
    }

    // Use "postBy" to mimic the old behaviour where we were looking up just a single post by slug
    const postBy = postEdge.node;
    const category = calculateCategory(postBy.categories.edges);

    if (Array.isArray(postSlug) && pageType !== 'Local') {
      // Slice the categories from the full slug up to 1 from the end of the slug array
      // (the last element in the array is the actual post slug itself)
      const categorySlugInUrl = postSlug.slice(0, -1).join('/');

      if (categorySlugInUrl !== category.slug) {
        // If the category slug as it appears in the URL is not the same as the WordPress category slug from
        // the database (e.g. if someone has mistyped/autocorrected spellings of categories when sharing a
        // link), then do a 301 redirect to the correct URL
        const redirectDestinationUrl = `/${pageType.toLowerCase()}/${
          category.slug
        }/${postSlug.slice(-1)[0]}`;

        return {
          redirect: { statusCode: 301, destination: redirectDestinationUrl },
        };
      }
    }

    // For Yoast SEO title, 'Second Nature Guides' is appended to all guide/story/blog posts as the site
    // name, so switch this back to Second Nature Stories / Second Nature Blog if applicable
    let sitenameReplace;
    switch (category.name) {
      case 'Stories':
        sitenameReplace = 'Second Nature Stories';
        break;
      case 'Blog':
        sitenameReplace = 'Second Nature Blog';
        break;
      default:
    }

    const seoTitle = sitenameReplace
      ? postBy.seo.title.replace('Second Nature Guides', sitenameReplace)
      : postBy.seo.title;

    const countryCode = getCountryCodeFromLocale(locale);
    const countryPricingDetails = countryCodePricingMap[countryCode];

    let currencySymbol = countryPricingDetails.currencySymbol;
    const noTechPricePerMonth = countryPricingDetails.noTechPricePerMonth;
    const ongoingPricePerMonth = countryPricingDetails.ongoingPricePerMonth;

    // Replace pricing tags for our programme with the latest price

    let contentHTML: string = postBy.content || '';
    contentHTML = contentHTML.replace(
      /%%NO_TECH_MONTHLY_PRICE%%/g,
      `${currencySymbol}${noTechPricePerMonth}`,
    );
    contentHTML = contentHTML.replace(
      /%%ONGOING_MONTHLY_PRICE%%/g,
      `${currencySymbol}${ongoingPricePerMonth}`,
    );

    let techPricePerMonth: number;
    if (countryPricingDetails.techPricePerMonth) {
      techPricePerMonth = countryPricingDetails.techPricePerMonth;
    } else {
      // If the user is from a country where the tech package is not available, use GB as default
      techPricePerMonth = countryCodePricingMap['GB'].techPricePerMonth || 55;
      currencySymbol = '£';
    }

    contentHTML = contentHTML.replace(
      /%%TECH_MONTHLY_PRICE%%/g,
      `${currencySymbol}${techPricePerMonth}`,
    );

    // Use lazy loading for images if the browser supports it
    contentHTML = contentHTML.replace(/<img/g, '<img loading="lazy"');

    return {
      props: {
        allCategories: json.data.allCategories,
        authorImageUrl: postBy.author ? postBy.author.avatar.url : '',
        authorName: postBy.author ? postBy.author.name : '',
        category: category.name,
        categorySlug: category.slug,
        commentsTree: postBy.comments,
        contentHTML,
        datePublished: postBy.date,
        featuredImageUrl: postBy.featuredImage
          ? postBy.featuredImage.sourceUrl
          : '',
        featuredImageWidth: postBy.featuredImage
          ? postBy.featuredImage.mediaDetails.width
          : 0,
        featuredImageHeight: postBy.featuredImage
          ? postBy.featuredImage.mediaDetails.height
          : 0,
        featuredImageSizes: postBy.featuredImage
          ? postBy.featuredImage.mediaDetails.sizes
          : [''],
        metaDescription: postBy.seo.metaDesc,
        postId: postBy.postId,
        sameCategoryPosts: combinePostsForCountryVariants(
          json.data.sameCategoryPosts,
          Number.MAX_SAFE_INTEGER, // we are fetching all the posts for the category without paging, so use no limit
        ),
        seoTitle,
        slug,
        title: postBy.title,
      },
      // Refresh this page at most every 300 seconds
      // If a request comes in within 300 seconds, it will serve the existing cached page
      // If a request comes in outside of 300 seconds, it will serve the cached page - but re-fetch the page in the background
      revalidate: 300,
    };
  } catch (err) {
    ErrorTracking.track(err, {
      message: `Error fetching guides data for post`,
      options: { postSlug, locale },
    });
    // We do not handle this error, but it is handled by NextJS https://github.com/vercel/next.js/discussions/11180#discussioncomment-2399
    throw err;
  }
};

export const LOCAL_GUIDES_POSTS_PER_PAGE = 10;

export interface LocalPageParams extends ParsedUrlQuery {
  after: string | undefined;
}

export const handleLocalPageStaticProps: GetStaticProps<
  LocalPageProps,
  LocalPageParams
> = async ({ params: { after } = {}, locale }) => {
  // There can be a maximum of 2 country variants per post - one for all countries and one for this locale -
  // so request double the number of posts before combining them
  const paginationArgs = `first: ${LOCAL_GUIDES_POSTS_PER_PAGE * 2},
    ${after ? `after: "${after}",` : ''}`;

  try {
    const query = `
      ${CategoryFieldsFragment}
      query {
        recentPosts: posts(${paginationArgs}
          where: {
          categoryName: "Local"
          orderby:{
            field:DATE
            order:DESC
          }
          tagSlugIn: [ "all_countries", "${locale}" ]
        }) {
          pageInfo {
            endCursor
            hasNextPage
          }
          edges {
            cursor
            node {
              title
              slug
              seo {
                metaDesc
              }
              author {
                name
                avatar {
                  url
                }
              }
              categories(where: {shouldOutputInFlatList: true}) {
                edges {
                  node {
                    ...CategoryFields
                  }
                }
              }
              featuredImage {
                mediaDetails {
                  sizes {
                    name
                    sourceUrl
                  }
                }
              }
            }
          }
        }
      }
    `;

    const json = await postGuidesQuery(query);

    return {
      props: {
        afterCursor: after || null,
        recentPosts: combinePostsForCountryVariants(
          json.data.recentPosts,
          LOCAL_GUIDES_POSTS_PER_PAGE,
        ),
      },
      // Refresh this page at most every 300 seconds
      // If a request comes in within 300 seconds, it will serve the existing cached page
      // If a request comes in outside of 300 seconds, it will serve the cached page - but re-fetch the page in the background
      revalidate: 300,
    };
  } catch (err) {
    ErrorTracking.track(err);
    // We do not handle this error, but it is handled by NextJS https://github.com/vercel/next.js/discussions/11180#discussioncomment-2399
    throw err;
  }
};

/**
 * Returns the URL for the author's page based on the author's name.
 * @param authorFullName - The name of the author.
 * @param includeAbsoluteBaseUrl - Optional. Specifies whether to include the absolute base URL in the returned URL. Default is false.
 * @returns The URL for the author's page.
 */
export const getAuthorURL = (
  authorFullName: string,
  includeAbsoluteBaseUrl?: boolean,
): string => {
  const prefix = includeAbsoluteBaseUrl ? `${getAbsoluteBaseURL()}` : '';

  return `${prefix}/guides/author/${authorFullName
    .replace(' and ', '-and-') // Treat 'Chris and Mike' as a first name
    .split(' ')[0]
    .toLowerCase()}`;
};
